var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('form-wizard',{staticClass:"steps-transparent mb-5",attrs:{"color":"#7367f0","title":null,"subtitle":null,"finish-button-text":"Submit","back-button-text":"Previous","skip-button-text":"Skip"},on:{"on-complete":_vm.updateCompany}},[_c('tab-content',{attrs:{"title":"Edit Company Details","icon":"feather icon-file-text "}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-flex"},[_c('div',[_c('feather-icon',{staticClass:"mr-2",attrs:{"icon":"ArrowLeftIcon","size":"24"},on:{"click":_vm.goBack}})],1),_c('div',[_c('h5',{staticClass:"mb-0"},[_vm._v("Company Details")]),_c('small',{staticClass:"text-muted"},[_vm._v("Edit Company Details. ")])])]),_c('b-form-checkbox',{attrs:{"name":"status","value":"Active","unchecked-value":"InActive","switch":"","inline":""},model:{value:(_vm.companyData.status),callback:function ($$v) {_vm.$set(_vm.companyData, "status", $$v)},expression:"companyData.status"}})],1)]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Company Name"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Company Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"company_name","placeholder":"","maxlength":"50"},model:{value:(_vm.companyData.company_name),callback:function ($$v) {_vm.$set(_vm.companyData, "company_name", $$v)},expression:"companyData.company_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Email"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"company_email_id","placeholder":"","maxlength":"50"},model:{value:(_vm.companyData.email_id),callback:function ($$v) {_vm.$set(_vm.companyData, "email_id", $$v)},expression:"companyData.email_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('h3',{staticClass:"top-header mb-2 mt-2"},[_vm._v("Address Information")]),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Address"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"name":"company_address","placeholder":"Textarea","rows":"3","maxlength":"100"},model:{value:(_vm.companyData.company_address),callback:function ($$v) {_vm.$set(_vm.companyData, "company_address", $$v)},expression:"companyData.company_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Country"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.countryList,"label":"name","reduce":function (e) { return e.id; }},on:{"input":_vm.getState},model:{value:(_vm.companyData.country),callback:function ($$v) {_vm.$set(_vm.companyData, "country", $$v)},expression:"companyData.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" State"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.stateList,"label":"name","reduce":function (e) { return e.id; },"clearable":true},on:{"input":_vm.getCity},model:{value:(_vm.companyData.state),callback:function ($$v) {_vm.$set(_vm.companyData, "state", $$v)},expression:"companyData.state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" City"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.cityList,"label":"name","reduce":function (e) { return e.id; }},model:{value:(_vm.companyData.city),callback:function ($$v) {_vm.$set(_vm.companyData, "city", $$v)},expression:"companyData.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1),_c('tab-content',{attrs:{"title":"Edit Owner","icon":"feather icon-map-pin"}},[_c('validation-observer',{ref:"simpleRules"},[_c('div',{staticClass:"mb-5"},[_c('h3',{staticClass:"top-header mb-2"},[_vm._v("Owner Information")]),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Name"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Name","rules":{ required: true, max: 50 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"name":"name","type":"text","placeholder":"Enter Name","maxlength":"50"},model:{value:(_vm.companyData.owners.name),callback:function ($$v) {_vm.$set(_vm.companyData.owners, "name", $$v)},expression:"companyData.owners.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Email"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Email","rules":{ required: true, email: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"email","name":"email","placeholder":"Email"},model:{value:(_vm.companyData.owners.email),callback:function ($$v) {_vm.$set(_vm.companyData.owners, "email", $$v)},expression:"companyData.owners.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Phone"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Phone","rules":{ required: true, max: 10 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"phone","name":"phone_no","placeholder":"Enter Phone No"},model:{value:(_vm.companyData.owners.phone_no),callback:function ($$v) {_vm.$set(_vm.companyData.owners, "phone_no", $$v)},expression:"companyData.owners.phone_no"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Status ")]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"d-flex mt-1"},[_c('b-form-radio',{staticClass:"mr-1",attrs:{"name":"status","value":"Active"},model:{value:(_vm.companyData.owners.status),callback:function ($$v) {_vm.$set(_vm.companyData.owners, "status", $$v)},expression:"companyData.owners.status"}},[_vm._v("Active")]),_c('b-form-radio',{attrs:{"name":"status","value":"Inactive"},model:{value:(_vm.companyData.owners.status),callback:function ($$v) {_vm.$set(_vm.companyData.owners, "status", $$v)},expression:"companyData.owners.status"}},[_vm._v("InActive")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('h3',{staticClass:"top-header mt-2"},[_vm._v("Address Information")]),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Address"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Address","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"type":"text","name":"address","placeholder":"","rows":"3","maxlength":"100"},model:{value:(_vm.companyData.owners.address),callback:function ($$v) {_vm.$set(_vm.companyData.owners, "address", $$v)},expression:"companyData.owners.address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Country"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.countryList,"label":"name","reduce":function (e) { return e.id; }},on:{"input":_vm.getState},model:{value:(_vm.companyData.owners.country),callback:function ($$v) {_vm.$set(_vm.companyData.owners, "country", $$v)},expression:"companyData.owners.country"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" State"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"State","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.stateList,"label":"name","reduce":function (e) { return e.id; }},on:{"input":_vm.getCity},model:{value:(_vm.companyData.owners.state),callback:function ($$v) {_vm.$set(_vm.companyData.owners, "state", $$v)},expression:"companyData.owners.state"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" City"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"City","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.cityList,"reduce":function (e) { return e.id; },"label":"name"},model:{value:(_vm.companyData.owners.city),callback:function ($$v) {_vm.$set(_vm.companyData.owners, "city", $$v)},expression:"companyData.owners.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }