<template>
	<b-row>
		<b-col cols="12">
			<form-wizard
				color="#7367f0"
				:title="null"
				:subtitle="null"
				finish-button-text="Submit"
				back-button-text="Previous"
				skip-button-text="Skip"
				class="steps-transparent mb-5"
				@on-complete="updateCompany"
			>
				<!-- Company detail tab -->
				<tab-content title="Edit Company Details" icon="feather icon-file-text ">
					<validation-observer ref="simpleRules">
						<b-row>
							<b-col cols="12" class="mb-2">
								<div class="d-flex justify-content-between">
									<div class="d-flex">
										<div>
											<feather-icon icon="ArrowLeftIcon" size="24" class="mr-2" @click="goBack" />
										</div>
										<div>
											<h5 class="mb-0">Company Details</h5>
											<small class="text-muted">Edit Company Details. </small>
										</div>
									</div>
									<b-form-checkbox
										v-model="companyData.status"
										name="status"
										value="Active"
										unchecked-value="InActive"
										switch
										inline
									>
									</b-form-checkbox>
								</div>
							</b-col>
							<b-col md="6">
								<b-form-group>
									<template v-slot:label> Company Name<span class="text-danger">*</span> </template>
									<validation-provider #default="{ errors }" name="Company Name" rules="required">
										<b-form-input
											v-model="companyData.company_name"
											name="company_name"
											placeholder=""
											maxlength="50"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>
							<b-col md="6">
								<b-form-group>
									<template v-slot:label> Email<span class="text-danger">*</span> </template>
									<validation-provider #default="{ errors }" name="Email" rules="required|email">
										<b-form-input
											v-model="companyData.email_id"
											name="company_email_id"
											placeholder=""
											maxlength="50"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>
						</b-row>

						<h3 class="top-header mb-2 mt-2">Address Information</h3>
						<b-row>
							<b-col md="12">
								<b-form-group>
									<template v-slot:label> Address<span class="text-danger">*</span> </template>
									<validation-provider #default="{ errors }" name="Address" rules="required">
										<b-form-textarea
											name="company_address"
											v-model="companyData.company_address"
											placeholder="Textarea"
											rows="3"
											maxlength="100"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>
							<b-col md="6">
								<b-form-group>
									<template v-slot:label> Country<span class="text-danger">*</span> </template>
									<validation-provider #default="{ errors }" name="Country" rules="required">
										<v-select
											v-model="companyData.country"
											:options="countryList"
											label="name"
											:reduce="(e) => e.id"
											@input="getState"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>
							<b-col md="6">
								<b-form-group>
									<template v-slot:label> State<span class="text-danger">*</span> </template>
									<validation-provider #default="{ errors }" name="State" rules="required">
										<v-select
											v-model="companyData.state"
											:options="stateList"
											label="name"
											:reduce="(e) => e.id"
											@input="getCity"
											:clearable="true"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>

							<b-col md="6">
								<b-form-group>
									<template v-slot:label> City<span class="text-danger">*</span> </template>
									<validation-provider #default="{ errors }" name="City" rules="required">
										<v-select v-model="companyData.city" :options="cityList" label="name" :reduce="(e) => e.id" />
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</b-form-group>
							</b-col>

							<!-- submit and reset -->
						</b-row>
					</validation-observer>
				</tab-content>

				<!-- Edit User -->
				<tab-content title="Edit Owner" icon="feather icon-map-pin">
					<validation-observer ref="simpleRules">
						<div class="mb-5">
							<h3 class="top-header mb-2">Owner Information</h3>
							<b-row>
								<b-col md="6">
									<b-form-group>
										<template v-slot:label> Name<span class="text-danger">*</span> </template>
										<validation-provider #default="{ errors }" name="Name" :rules="{ required: true, max: 50 }">
											<b-form-input
												v-model="companyData.owners.name"
												name="name"
												type="text"
												placeholder="Enter Name"
												maxlength="50"
											/>
											<small class="text-danger">{{ errors[0] }}</small>
										</validation-provider>
									</b-form-group>
								</b-col>
								<b-col md="6">
									<b-form-group>
										<template v-slot:label> Email<span class="text-danger">*</span> </template>
										<validation-provider #default="{ errors }" name="Email" :rules="{ required: true, email: true }">
											<b-form-input type="email" v-model="companyData.owners.email" name="email" placeholder="Email" />
											<small class="text-danger">{{ errors[0] }}</small>
										</validation-provider>
									</b-form-group>
								</b-col>

								<b-col md="6">
									<b-form-group>
										<template v-slot:label> Phone<span class="text-danger">*</span> </template>
										<validation-provider #default="{ errors }" name="Phone" :rules="{ required: true, max: 10 }">
											<b-form-input
												type="phone"
												v-model="companyData.owners.phone_no"
												name="phone_no"
												placeholder="Enter Phone No"
											/>
											<small class="text-danger">{{ errors[0] }}</small>
										</validation-provider>
									</b-form-group>
								</b-col>

								<b-col md="6">
									<b-form-group>
										<template v-slot:label> Status </template>
										<validation-provider #default="{ errors }" name="Status" rules="required">
											<div class="d-flex mt-1">
												<b-form-radio v-model="companyData.owners.status" name="status" value="Active" class="mr-1"
													>Active</b-form-radio
												>
												<b-form-radio v-model="companyData.owners.status" name="status" value="Inactive"
													>InActive</b-form-radio
												>
											</div>

											<small class="text-danger">{{ errors[0] }}</small>
										</validation-provider>
									</b-form-group>
								</b-col>
							</b-row>
							<!-- address information -->
							<h3 class="top-header mt-2">Address Information</h3>
							<b-row>
								<b-col md="12">
									<b-form-group>
										<template v-slot:label> Address<span class="text-danger">*</span> </template>
										<validation-provider #default="{ errors }" name="Address" rules="required">
											<b-form-textarea
												type="text"
												v-model="companyData.owners.address"
												name="address"
												placeholder=""
												rows="3"
												maxlength="100"
											/>
											<small class="text-danger">{{ errors[0] }}</small>
										</validation-provider>
									</b-form-group>
								</b-col>
								<b-col md="6">
									<b-form-group>
										<template v-slot:label> Country<span class="text-danger">*</span> </template>
										<validation-provider #default="{ errors }" name="Country" rules="required">
											<v-select
												v-model="companyData.owners.country"
												:options="countryList"
												label="name"
												:reduce="(e) => e.id"
												@input="getState"
											/>
											<small class="text-danger">{{ errors[0] }}</small>
										</validation-provider>
										<!--   @input="getCity" -->
									</b-form-group>
								</b-col>
								<b-col md="6">
									<b-form-group>
										<template v-slot:label> State<span class="text-danger">*</span> </template>
										<validation-provider #default="{ errors }" name="State" rules="required">
											<v-select
												v-model="companyData.owners.state"
												:options="stateList"
												label="name"
												:reduce="(e) => e.id"
												@input="getCity"
											/>
											<small class="text-danger">{{ errors[0] }}</small>
										</validation-provider>
									</b-form-group>
								</b-col>

								<b-col md="6">
									<b-form-group>
										<template v-slot:label> City<span class="text-danger">*</span> </template>
										<validation-provider #default="{ errors }" name="City" rules="required">
											<v-select
												v-model="companyData.owners.city"
												:options="cityList"
												:reduce="(e) => e.id"
												label="name"
											/>
											<small class="text-danger">{{ errors[0] }}</small>
										</validation-provider>
									</b-form-group>
								</b-col>

								<!-- submit and reset -->
							</b-row>
						</div>
					</validation-observer>
				</tab-content>
			</form-wizard>
		</b-col>
	</b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import axiosIns from "@/libs/axios";
import { BASE_URL } from "@core/common/constants";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { required, email, alpha } from "@validations";

export default {
	components: {
		FormWizard,
		TabContent,
		vSelect,
	},
	data() {
		return {
			emailValue: "",
			name: "",
			alpha,
			required,
			email,
			stateList: [],
			countryList: [],
			cityList: [],
			selectedState: null,
			selectedCountry: null,
			company_country: null,
			selectedCity: null,
			user_image: null,
			company_name: null,
			company_address: null,
			selected_state: null,
			company_email_id: null,
			formData: {
				name: null,
				email: null,
				user_name: null,
				password: null,
				status: null,
				phone_no: null,
				address: null,
			},
			companyData: {
				owners: {
					name: null,
					email: null,
					status: null,
					phone_no: null,
					address: null,
					state: null,
					country: null,
					city: null,
				},
			},
			user_image: null,
			url: null,
			companyData: {},
			// user_role: window.location.pathname.split("/")[2],
		};
	},
	created: function () {
		this.getCountry();
		this.getCompanyById();
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		getState(id) {
			axiosIns
				.get(`web/get-state-list?country_id=${id}`)
				.then((response) => {
					this.stateList = response.data.state_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		getCountry() {
			axiosIns
				.get(`web/get-country-list`)
				.then((response) => {
					// this.companyData.district = "";
					this.countryList = response.data.country_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		getCity(id) {
			axiosIns
				.get(`web/get-city-list?state_id=${id}`)
				.then((response) => {
					// this.companyData.city = "";
					this.cityList = response.data.city_list;
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		getCompanyById() {
			const id = this.$route.params.companyId;
			axiosIns
				.get(`web/companies/${id}`)
				.then((response) => {
					this.companyData = response.data.company;
					this.companyData.owners = response.data.company.owners[0];
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},

		updateCompany() {
			const id = this.$route.params.companyId;
			this.companyData.user_role = "owner";
			let formData = new FormData();
			formData.append("_method", "PUT");
			formData.append("owner_id", this.companyData.owners.id);

			let form_data = this.objectToFormData(this.companyData, formData);
			axiosIns
				.post(`web/companies/${id}`, form_data)
				.then((response) => {
					response;
					this.$toast({
						component: ToastificationContent,
						position: "top-right",
						props: {
							icon: "CoffeeIcon",
							variant: "warning",
							text: `Data Updated Successfully `,
						},
					});
					this.$router.push({
						path: `/apps/company`,
					});
				})
				.catch((error) => {
					this.$refs.setErrors(error.response.data.error);
				});
		},
	},
};
</script>
<style lang="scss">
@import "@core/scss/vue/pages/nav-role.scss";
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
